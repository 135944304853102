<template>
  <div>
    <section class="">
      <!-- desktop -->
      <div class="hidden md:block">
        <div class="w-full max-w-7xl mx-auto">
          <!-- <div class="flex gap-8 justify-center py-[70px]">
            <div>
              <h1 class="text-5xl text-blue-400 font-bold">13,000+</h1>
              <p class="text-black text-sm">Students</p>
            </div>
            <div class="border-r border-[#596c77]"></div>
            <div>
              <h1 class="text-5xl text-blue-400 font-bold">9000+</h1>
              <p class="text-black text-sm">Faculty</p>
            </div>
            <div class="border-r border-[#596c77]"></div>
            <div>
              <h1 class="text-5xl text-blue-400 font-bold">270+</h1>
              <p class="text-black text-sm">Collaborations</p>
            </div>
          </div> -->

          <div class="w-full relative bg-[#f6f6f6]">
            <img
              src="@/assets/program/2.webp"
              alt=""
              class="w-full h-[600px]"
            />
            <div class="w-full max-w-7xl mx-auto">
              <div
                class="absolute flex flex-col justify-center bg-opacity-90 px-10 text-center top-0"
              >
                <h2
                  class="uppercase text-[24px] lg:text-[30px] font-bold pb-6 pt-6 text-[#17479e] text-center"
                >
                  About Sharda Online
                </h2>
                <p class="text-left">
                  Sharda Online, the e-learning arm of Sharda University, is a
                  new initiative undertaken to provide learning opportunities to
                  aspirational learners with the flexibility to enhance their
                  knowledge without leaving their homes. We emphasize
                  experiential online learning opportunities throughout the
                  program curriculum, including live online classes, recorded
                  video lectures, self-learning material, course assignments,
                  discussion forums for peer-group learning, and other
                  industry-driven projects.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- mobile -->
      <div class="block md:hidden">
        <!-- <div class="w-full">
          <div class="flex gap-4 justify-center py-[70px]">
            <div class="bg-white border p-3 shadow-2xl rounded-md">
              <h1 class="text-xl text-blue-400 font-bold">13,000+</h1>
              <p class="text-black text-sm">student</p>
            </div>

            <div class="bg-white border p-3 shadow-2xl rounded-md">
              <h1 class="text-xl text-blue-400 font-bold">9000+</h1>
              <p class="text-black text-sm">Faculty</p>
            </div>

            <div class="bg-white border p-3 shadow-2xl rounded-md">
              <h1 class="text-xl text-blue-400 font-bold">270+</h1>
              <p class="text-black text-sm">Collaborations</p>
            </div>
          </div>
        </div> -->
        <div class="w-full relative h-[630px] bg-[#f6f6f6]">
          <img
            src="@/assets/program/2.webp"
            alt=""
            class="w-full h-[300px] absolute bottom-0"
          />
          <div class="w-full mx-auto max-w-7xl">
            <div
              class="absolute flex flex-col justify-center bg-opacity-90 p-10 text-center top-0"
            >
              <h2 class="uppercase text-xl font-bold pb-2 pt-2 text-blue-800">
                About Sharda Online
              </h2>
              <p class="text-left">
                Sharda Online, the e-learning arm of Sharda University, is a new
                initiative undertaken to provide learning opportunities to
                aspirational learners with the flexibility to enhance their
                knowledge without leaving their homes. We emphasize experiential
                online learning opportunities throughout the program curriculum,
                including live online classes, recorded video lectures,
                self-learning material, course assignments, discussion forums
                for peer-group learning, and other industry-driven projects.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "AboutView",
};
</script>

<style></style>
