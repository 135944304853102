<template>
  <div class="bg-black">
    <div class="w-full flex md:flex-row flex-col max-w-7xl mx-auto">
      <div class="flex p-3 w-[50%] gap-5">
        <a v-if="phone" :href="'tel:+91' + phone">
          <div class="flex gap-2 items-center">
            <img
              src="@/assets/program/Iconmaterial-call.svg"
              alt=""
              class="w-[20px] h-[20px]"
            />
            <p class="text-white text-sm md:text-lg">+91{{ phone }}</p>
          </div>
        </a>
      </div>

      <div class="flex w-[50%] p-3 gap-5 md:justify-end ">
        <a v-if="email">
          <div class="flex gap-2 items-center m-auto">
            <img
              src="@/assets/program/icon-message.svg"
              alt=""
              class="w-[20px] h-[20px]"
            />
            <p class="text-white text-sm md:text-lg">{{ email }}</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterView",
  props: ["phone", "email"],
  created() {
    console.log("Received phone prop in FooterView:", this.phone);
    console.log("Received email prop in FooterView:", this.email);
  },
};
</script>

<style></style>
