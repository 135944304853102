import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { createHead } from "@unhead/vue";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "jquery";
const head = createHead();
createApp(App).use(store).use(router).use(head).mount("#app");
