<template>
  <div>
    <BannerViewDmfc v-if="slug === 'accredited-online-degrees'" :dataValue="programData"
      :dataPartner="programData.partner_name" />
    <BannerView v-else :dataValue="programData" :dataPartner="programData.partner_name" />
    <AboutView />
    <AwardsView />
    <OnlineProgram />
    <HiglightView />
    <WhoApply />
    <HiringView />
    <CareerSupport />
    <!-- <FaqView /> -->
    <FaqView v-if="this.lpProgramfaq" :faq="this.lpProgramfaq" />
    <div class="pb-[80px] md:pb-0">
      <FooterView :phone="programData.phone_number" :email="programData.email" />
    </div>
    <LpStickyView :wattsapp="programData.whatsapp_url" :phone="programData.phone_number" />
  </div>
</template>

<script>
import axios from "axios";
import { useHead } from "@unhead/vue";
import BannerView from "@/components/LearningShala/BannerView.vue";
import BannerViewDmfc from "@/components/LearningShala/BannerViewDmfc.vue";
import AboutView from "@/components/LearningShala/AboutView.vue";
import AwardsView from "@/components/LearningShala/AwardsView.vue";
import OnlineProgram from "@/components/LearningShala/OnlineProgram.vue";
import HiglightView from "@/components/LearningShala/HiglightView.vue";
import WhoApply from "@/components/LearningShala/WhoApply.vue";
import HiringView from "@/components/LearningShala/HiringView.vue";
import CareerSupport from "@/components/LearningShala/CareerSupport.vue";
import FaqView from "@/components/LearningShala/FaqView.vue";
import FooterView from "@/components/LearningShala/FooterView.vue";
import LpStickyView from "@/components/LearningShala/LpStickyView.vue";

export default {
  name: "LP_Program",
  data() {
    return {
      programData: [],
      lpProgramfaq: [],
    };
  },
  props: ["slug"],
  components: {
    BannerView,
    AboutView,
    AwardsView,
    OnlineProgram,
    HiglightView,
    WhoApply,
    HiringView,
    CareerSupport,
    FaqView,
    FooterView,
    LpStickyView,
    BannerViewDmfc,
  },
  mounted() {
    // Check if the slug matches "accredited-online-degrees"
    if (this.slug === 'accredited-online-degrees') {
      // Create the <noscript> tag with the iframe
      const noscriptTag = document.createElement('noscript');
      noscriptTag.innerHTML = `
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-PZD6DKGW"
          height="0"
          width="0"
          style="display:none;visibility:hidden">
        </iframe>
      `;
      // Insert <noscript> tag immediately after the <body> tag
      document.body.insertAdjacentElement('afterbegin', noscriptTag);
    }
    if (this.slug === 'online-education') {
      // Create the <noscript> tag with the iframe
      const noscriptTag = document.createElement('noscript');
      noscriptTag.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K22RC979"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
      // Insert <noscript> tag immediately after the <body> tag
      document.body.insertAdjacentElement('afterbegin', noscriptTag);
    }
  },
  created() {
    this.programInfo();
    let path = this.$route.path;
    console.log("path :", path);
    if (this.slug == "best-online-degree-programs") {
      useHead({
        script: [
          {
            src: "https://www.googletagmanager.com/gtag/js?id=AW-11473738362",
            async: true,
          },
          {
            innerHTML: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-11473738362');
          `,
            type: "text/javascript",
          },
        ],
        __dangerouslyDisableSanitizers: {
          script: ["innerHTML"],
        },
      });
    }

    if (this.slug == "online-education") {
      useHead({
        script: [
          {
            innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-K22RC979');`,
            type: 'text/javascript'
          },
          {
            src: "https://www.googletagmanager.com/gtag/js?id=AW-16668471893",
            async: true,
          },
          {
            innerHTML: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-16668471893');
            `,
            type: "text/javascript",
          },
        ],
        __dangerouslyDisableSanitizers: {
          script: ["innerHTML"],
        },
      });
    }


    if (this.slug == "accredited-online-degrees") {
      useHead({
        script: [
          {
            innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-PZD6DKGW');`,
            type: 'text/javascript'
          },
        ],

      });
    }

    if (this.slug == "top-online-degree-courses") {
      useHead({
        script: [
          {
            src: "https://www.googletagmanager.com/ns.html?id=GTM-5G66KJ27",
            async: true,
          },
          {
            innerHTML: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'GTM-5G66KJ27');
            `,
            type: "text/javascript",
          },
        ],
        __dangerouslyDisableSanitizers: {
          script: ["innerHTML"],
        },
      });
    }

    this.programFaq();
  },
  methods: {
    async programInfo() {
      await axios
        .get(`${process.env.VUE_APP_API}/lp/electives/?slug=${this.slug}`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.programData = resp.data.data[0];
            this.status = true;
            console.log("program data : ", this.programData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async programFaq() {
      await axios
        .get(`${process.env.VUE_APP_API}/lp/electives/faq`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.lpProgramfaq = resp.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
