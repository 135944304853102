<template>
  <div>
    <BannerViewDmfc
      :dataValue="programData"
      :dataPartner="programData.partner_name"
    />
    <AboutView />
    <AwardsView />
    <OnlineProgram />
    <HiglightView />
    <WhoApply />
    <HiringView />
    <CareerSupport />
    <!-- <FaqView /> -->
    <FaqView v-if="this.lpProgramfaq" :faq="this.lpProgramfaq" />
    <div class="pb-[80px] md:pb-0">
      <FooterView :phone="programData.phone_number" />
    </div>
    <LpStickyView
      :wattsapp="programData.whatsapp_url"
      :phone="programData.phone_number"
    />
  </div>
</template>

<script>
import axios from "axios";
import { useHead } from "@unhead/vue";
import BannerViewDmfc from "@/components/LearningShala/BannerViewDmfc.vue";
import AboutView from "@/components/LearningShala/AboutView.vue";
import AwardsView from "@/components/LearningShala/AwardsView.vue";
import OnlineProgram from "@/components/LearningShala/OnlineProgram.vue";
import HiglightView from "@/components/LearningShala/HiglightView.vue";
import WhoApply from "@/components/LearningShala/WhoApply.vue";
import HiringView from "@/components/LearningShala/HiringView.vue";
import CareerSupport from "@/components/LearningShala/CareerSupport.vue";
import FaqView from "@/components/LearningShala/FaqView.vue";
import FooterView from "@/components/LearningShala/FooterView.vue";
import LpStickyView from "@/components/LearningShala/LpStickyView.vue";

export default {
  name: "LP_Program",
  data() {
    return {
      programData: [],
      lpProgramfaq: [],
    };
  },
  props: ["slug"],
  components: {
    BannerViewDmfc,
    AboutView,
    AwardsView,
    OnlineProgram,
    HiglightView,
    WhoApply,
    HiringView,
    CareerSupport,
    FaqView,
    FooterView,
    LpStickyView,
  },
  mounted() {
    // Check if the slug matches "accredited-online-degrees"
    if (this.slug === 'accredited-online-degrees') {
      // Create the <noscript> tag with the iframe
      const noscriptTag = document.createElement('noscript');
      noscriptTag.innerHTML = `
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-PZD6DKGW"
          height="0"
          width="0"
          style="display:none;visibility:hidden">
        </iframe>
      `;
      // Insert <noscript> tag immediately after the <body> tag
      document.body.insertAdjacentElement('afterbegin', noscriptTag);
    }
  },

  created() {
    // this.programInfo();
    let path = this.$route.path;
    console.log("path :", path);
    if (this.slug == "best-online-degree-programs") {
      useHead({
        script: [
          {
            src: "https://www.googletagmanager.com/gtag/js?id=AW-11473738362",
            async: true,
          },
          {
            innerHTML: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-11473738362');
            `,
            type: "text/javascript",
          },
        ],
        __dangerouslyDisableSanitizers: {
          script: ["innerHTML"],
        },
      });
    }

    
    if (this.slug == "accredited-online-degrees") {
      useHead({
  script: [
    {
      innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-PZD6DKGW');`,
      type: 'text/javascript'
    },
  ],
 
});
    }
    
    // if (this.slug == "top-online-degree-courses") {
    //   useHead({
    //     script: [
    //       {
    //         src: "https://www.googletagmanager.com/ns.html?id=GTM-5G66KJ27",
    //         async: true,
    //       },
    //       {
    //         innerHTML: `
    //             window.dataLayer = window.dataLayer || [];
    //             function gtag(){dataLayer.push(arguments);}
    //             gtag('js', new Date());
    //             gtag('config', 'GTM-5G66KJ27');
    //           `,
    //         type: "text/javascript",
    //       },
    //     ],
    //     __dangerouslyDisableSanitizers: {
    //       script: ["innerHTML"],
    //     },
    //   });
    // }

    // this.programFaq();
  },
  methods: {
    // async programInfo() {
    //   await axios
    //     .get(`${process.env.VUE_APP_API}/lp/electives/?slug=${this.slug}`)
    //     .then((resp) => {
    //       if (resp.data.status == 200) {
    //         this.programData = resp.data.data[0];
    //         this.status = true;
    //         console.log("program data : ", this.programData);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    // async programFaq() {
    //   await axios
    //     .get(`${process.env.VUE_APP_API}/lp/electives/faq`)
    //     .then((resp) => {
    //       if (resp.data.status == 200) {
    //         this.lpProgramfaq = resp.data.data;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    // async submitData() {
    //   if (!this.formData) {
    //     console.error("formData is undefined");
    //     return;
    //   }

    //   const dataJson = {
    //     source: "API-LEARNONLINE-SHARDA",
    //     data: [this.formData], 
    //   };

    //   try {
    //     const response = await axios.post(
    //       "https://lms.dmcfsacademy.com/api-external-learnonline-shardaonline",
    //       dataJson,
    //       {
    //         headers: {
    //           Authorization: "XBrKQdQZklVCCVN1iqCSNhqkjxmHgrjRuqMzzgP8nQw=",
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     );

    //     console.log("Response data:", response.data);
    //   } catch (error) {
    //     if (error.response) {
    //       console.error("API error:", error.response.data);
    //       console.error("Status code:", error.response.status);
    //     } else if (error.request) {
    //       console.error("No response received from API:", error.request);
    //     } else {
    //       console.error("Error setting up the request:", error.message);
    //     }
    //   }
    // },
    

    // async sendData() {
    //   const url =
    //     "https://lms.dmcfsacademy.com/api-external-learnonline-shardaonline";
    //   const data = {
    //     source: "API-LEARNONLINE-SHARDA",
    //     data: [
    //       {
    //         mobile: this.phoneNumber, // Use actual variable
    //         name: this.name, // Use actual variable
    //         email: this.email, // Use actual variable
    //         state: this.state, // Use actual variable
    //         city: this.city, // Use actual variable
    //         pincode: "410101",
    //         course: this.program, // Use actual variable
    //         program: this.elective, // Use actual variable
    //       },
    //     ],
    //   };

    //   try {
    //     const response = await axios.post(url, data, {
    //       headers: {
    //         Authorization: "XBrKQdQZklVCCVN1iqCSNhqkjxmHgrjRuqMzzgP8nQw=",
    //         "Content-Type": "application/json",
    //       },
    //     });
    //     console.log(response.data);
    //     // You could also handle success feedback here.
    //   } catch (error) {
    //     console.error("Error sending data:", error);
    //     // Consider showing a user-friendly message here.
    //   }
    // },
  },
};
</script>

<style></style>
