import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LP_ProgramDmfc from "../views/LP_ProgramDmfc";
import LP_Program from "../views/LP_Program.vue";
import LP_ProgramThankyou from "../views/LP_ProgramThankyou.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/lp/:slug",
    name: "LP_Programs",
    component: LP_Program,
    props: true,
  },
  {
    path: "/lp/accredited-online-degree",
    name: "LP_ProgramDmfc",
    component: LP_ProgramDmfc,
    props: true,
  },
  {
    path: "/lp/:slug/thankyou",
    name: "LP_ProgramThankyou",
    component: LP_ProgramThankyou,
    props: true,
  },
  // {
  //   path: "/lp/online-colleges-india",
  //   name: "LP_Program",
  //   component: LP_Program,
  // },
  // {
  //   path: "/lp/online-colleges-india/thankyou",
  //   name: "LP_ProgramThankyou",
  //   component: LP_ProgramThankyou,
  // },
  // {
  //   path: "/lp/online-degree-courses",
  //   name: "LP_Program",
  //   component: LP_Program,
  // },
  // {
  //   path: "/lp/online-degree-courses/thankyou",
  //   name: "LP_ProgramThankyou",
  //   component: LP_ProgramThankyou,
  // },
  // {
  //   path: "/lp/accredited-online-degrees",
  //   name: "LP_Program",
  //   component: LP_Program,
  // },
  // {
  //   path: "/lp/accredited-online-degrees/thankyou",
  //   name: "LP_ProgramThankyou",
  //   component: LP_ProgramThankyou,
  // },
  // {
  //   path: "/lp/online-degrees",
  //   name: "LP_Program",
  //   component: LP_Program,
  // },
  // {
  //   path: "/lp/online-degrees/thankyou",
  //   name: "LP_ProgramThankyou",
  //   component: LP_ProgramThankyou,
  // },
  // {
  //   path: "/lp/best-online-degree-programs",
  //   name: "LP_Program",
  //   component: LP_Program,
  // },
  // {
  //   path: "/lp/best-online-degree-programs/thankyou",
  //   name: "LP_ProgramThankyou",
  //   component: LP_ProgramThankyou,
  // },
  // {
  //   path: "/lp/online-courses",
  //   name: "LP_Program",
  //   component: LP_Program,
  // },
  // {
  //   path: "/lp/online-courses/thankyou",
  //   name: "LP_ProgramThankyou",
  //   component: LP_ProgramThankyou,
  // },
  // {
  //   path: "/lp/online-degree-programs",
  //   name: "LP_Program",
  //   component: LP_Program,
  // },
  // {
  //   path: "/lp/online-degree-programs/thankyou",
  //   name: "LP_ProgramThankyou",
  //   component: LP_ProgramThankyou,
  // },
  // {
  //   path: "/lp/best-online-degree-courses",
  //   name: "LP_Program",
  //   component: LP_Program,
  // },
  // {
  //   path: "/lp/best-online-degree-courses/thankyou",
  //   name: "LP_ProgramThankyou",
  //   component: LP_ProgramThankyou,
  // },
  // {
  //   path: "/lp/online-courses-india",
  //   name: "LP_Program",
  //   component: LP_Program,
  // },
  // {
  //   path: "/lp/online-courses-india/thankyou",
  //   name: "LP_ProgramThankyou",
  //   component: LP_ProgramThankyou,
  // },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
